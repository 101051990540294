import React from 'react';
import { makeStyles } from '@mui/styles';
import logoFile from '../assets/images/logo.png';

const useStyles = makeStyles(({
  logo: {
    height: 32
  }
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <img
      alt="Betswap.gg"
      src={logoFile}
      className={classes.logo}
      {...props}
    />
  );
};

export default Logo;
