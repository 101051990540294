  const networks = {

  // Testnet
  80001: {
    id: 80001,
    name: 'Matic Mumbai',
    token: '0xe10410f838F75115Fd72BA09a668fe88f18eCE0B',
    multisig: '0x9755591F097bc56B1525F76F1C3BEc1f2D963219',
  },

  3: {
    id: 3,
    name: 'Ropsten Ethereum',
    token: '0xe10410f838F75115Fd72BA09a668fe88f18eCE0B',
    multisig: '0x075768278c585766234C5939B9e63edC4f3C89D5'
  },

  4002: {
    id: 4002,
    name: 'Fantom Testnet',
    token: '0xe10410f838F75115Fd72BA09a668fe88f18eCE0B',
    multisig: '0x91a36E82807aDD2A78fCA4B27B813b75BeC9e6Da'
  },
  43113: {
    id: 43113,
    name: 'AVAX Testnet',
    token: '0xe10410f838F75115Fd72BA09a668fe88f18eCE0B',
    multisig: '0xF9D5cfF37D14E64670875c2111b0A7EC2798150A'
  },

  // Mainnet
  1: {
    id: 1,
    name: 'Ethereum',
    token: '0x69570f3E84f51Ea70b7B68055c8d667e77735a25',
    multisig: '0xcA2E9Cc886427D2B978874c320b585153347019D'
  },

  43114: {
    id: 43114,
    name: 'Avalanche',
    token: '0x63682bDC5f875e9bF69E201550658492C9763F89',
    multisig: '0x9Daf62eBa8b860511382AAe8d6a7b009b3373a7f'
  },

  250: {
    id: 250,
    name: 'Fantom',
    token: '0x5A33869045db8A6a16c9f351293501CFD92cf7ed',
    multisig: '0x2D8fF481a5bcb0a1870fA0C9645A5071D9Ada043'
  },
};

export const getNetwork = (id) => {
  return networks[id];
};

export const getNetworks = () => {
  let resultNetworks = [];

  let networkList = process.env.REACT_APP_NETWORKS.split(' ');
  
  for (let i in networkList) {
    let key = parseInt(networkList[i]);
    resultNetworks.push(networks[key]);
  }

  return resultNetworks;
};
