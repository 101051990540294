import Web3 from 'web3';

const legend = {
  // Token
  '0x095ea7b3': {
    name: 'approve',
    parameters: [{type: 'address', name: 'Spender'}, {type: 'uint256', name: 'Value', isAmount: true}]
  },

  // Staking
  '0xf2fde38b': {
    name: 'transferOwnership',
    parameters: [{type: 'address', name: 'newOwner'}]
  },
  '0x7cb6538c': {
    name: 'allocateBSGG',
    parameters: [{type: 'uint256', name: 'Amount', isAmount: true}, {type: 'uint256', name: 'TicketTypeId'}]
  },
  '0x4456abe5': {
    name: 'addTicketType',
    parameters: [{type: 'uint128', name: 'MinLockAmount', isAmount: true}, {type: 'uint32', name: 'LockDuration'}, {type: 'uint32', name: 'GainMultiplier'}, {type: 'uint16', name: 'Seasons'}]
  },
  '0xc814873f': {
    name: 'updateTicketType',
    parameters: [{type: 'uint32', name: 'Id'}, {type: 'uint128', name: 'MinLockAmount', isAmount: true}, {type: 'uint32', name: 'LockDuration'}, {type: 'uint32', name: 'GainMultiplier'}]
  },
  '0xeee5b15e': {
    name: 'deactivateTicketType',
    parameters: [{type: 'uint32', name: 'TicketTypeId'}]
  },
  '0x81f1f83e': {
    name: 'activateTicketType',
    parameters: [{type: 'uint32', name: 'TicketTypeId'}]
  },
  '0x9b8fd20a': {
    name: 'triggerEmergency',
    parameters: [{type: 'uint', name: 'Code'}]
  },
  '0xa23080e9': {
    name: 'enablePrivilegedMode',
    parameters: []
  },
  '0xe8019584': {
    name: 'disablePrivilegedMode',
    parameters: []
  },
  '0xde540fb9': {
    name: 'addPrivilegedAccounts',
    parameters: [{type: 'address[]', name: 'Accounts'}]
  },
  '0x433805e4': {
    name: 'removePrivilegedAccounts',
    parameters: [{type: 'address[]', name: 'Accounts'}]
  },
  '0xad709c05': {
    name: 'withdrawNonReservedBSGG',
    parameters: [{type: 'uint128', name: 'Amount', isAmount: true}, {type: 'uint32', name: 'TicketTypeId'}, {type: 'uint16', name: 'SeasonId'}, {type: 'address', name: 'Receiver'}]
  },
  '0x7e6c38ba': {
    name: 'changeMinMaxLimits',
    parameters: [{type: 'uint', name: 'MinAmount', isAmount: true}, {type: 'uint', name: 'MaxAmount', isAmount: true}, {type: 'bool', name: 'Status'}]
  },

  // Multisig
  '0x3aeac4e1': {
    name: 'withdrawToken',
    parameters: [{type: 'address', name: 'Token'}, {type: 'address', name: 'Receiver'}]
  },
};


export const getAttributes = (txData) => {
  let web3 = new Web3();
  let m = txData.substring(0, 10);
  let attributes = [];
  let methodFound = null;

  for (let i in legend) {
    if (m == i) {
      methodFound = legend[i];
      break;
    }
  }

  if (methodFound === null) {
    return attributes;
  }

  if (methodFound.parameters.length === 0) {
    return attributes;
  }

  let data = web3.eth.abi.decodeParameters(methodFound.parameters, txData.substring(10, txData.length));

  let counter = 0;

  for (let i in data) {
    if (i == 0 || (i + 1) >= 2 || i === '__length__') {
      continue;
    }
  
    attributes.push({name: i, value: data[i], isAmount: (methodFound.parameters[counter].isAmount === true) ? true : false});

    counter ++;
  }

  return attributes;
};

export const getMethodName = (txData) => {
  let m = txData.substring(0, 10);
  
  let result = m;

  if (legend[m]) {
    result = legend[m].name;
  }

  return result;
};
