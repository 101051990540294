import create from 'zustand';
import detectEthereumProvider from '@metamask/detect-provider';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';

import tokenAbi from 'src/_data/tokentAbi.json';
import multisigAbi from 'src/_data/multisigAbi.json';

import { getNetwork } from 'src/_data/networks';

const useStore = create((set, get) => ({
  ethereum: null,
  provider: null,
  address: null,
  network: null,
  networkSettings: null,
  networkChanged: false, // Flag if network is changed / reload required
  token: null, // Token Smart Contract
  multisig: null, // Multisig Smart Contract
  token: null, // Token Smart Contract


  initialize: async (networkId = null) => {
    const { connectMetamask, changeNetwork, networkSettings } = await get();

    if (networkId !== null) {
      let networkSettings = await getNetwork(parseInt(networkId));
      await set(state => ({networkSettings}));
    }

    //await set({networkChanged: false});

    //console.log('Store Network Set');

    /*
    // Set first network by default
    if (networkSettings === null) {
      let allNetworks = await getNetworks();

      if (allNetworks[0]) {
        changeNetwork(allNetworks[0].id);
      }

    }
    */

    await connectMetamask();
  },

  async changeNetwork(id) {
    let networkSettings = await getNetwork(parseInt(id));
    set({networkSettings});
  },

  async logout() {
    const { ethereum, connect } = await get();
    if (ethereum?.disconnect) {
      await ethereum?.disconnect();
    }

    localStorage.setItem('ethereum_provider', 'none');
    set({ ethereum: null, address: null, token: null, multisig: null  });
    connect();
  },

  async requestNetworkChange() {
    const { ethereum, networkSettings } = await get();
    set({ address: null, token: null, multisig: null  });

    const chainId = `0x${networkSettings.id.toString('16')}`;

    await ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }],
        })
        .catch((err) => err);
    
  },


  async connectMetamask() {
    const { subscribeEthEvents, connect } = await get();
    const ethereum = await detectEthereumProvider();
    if (!ethereum) {
      toast.error('Please install MetaMask');
      set({ ethereum: null });
      return false;
    }
    await subscribeEthEvents(ethereum);
    const address = await ethereum.selectedAddress;



    if (address) {
      await set({ ethereum, address });
    } else {
      await set({ ethereum });
    }
    
    
    localStorage.setItem('ethereum_provider', 'metamask');
    await connect();
    return true;
  },

  async connect() {
    const { ethereum, networkSettings, requestNetworkChange, networkChanged } = await get();
    const nextState =  await get();

    if (ethereum === null || networkSettings === null) return set(nextState);
    
    if (await ethereum.isMetaMask) {
      if (ethereum.selectedAddress) {
        nextState.address = ethereum.selectedAddress;
      }
      
      const isAccountPresent = !!(await ethereum.request({ method: 'eth_requestAccounts' }));

      const isEthereumConnected = await ethereum.isConnected();
      if (!isAccountPresent && !isEthereumConnected) {
        return set(nextState);
      }
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    const network = await provider.getNetwork();

    nextState.network = network;

    if (network.chainId !== networkSettings.id && networkChanged === false) {
      nextState.provider = null;
      //nextState.network = null;
      //nextState.networkChanged = true;
      //toast.error('Please change the network to ' + networkSettings.name);
      await requestNetworkChange();
      return set(nextState);
      //return false;
    }

    if (await ethereum.selectedAddress) {
      nextState.address = await ethereum.selectedAddress;
    }

    nextState.networkChanged = false;
    nextState.provider = provider;

    nextState.token = new ethers.Contract(networkSettings.token, tokenAbi, provider);

    nextState.multisig = new ethers.Contract(networkSettings.multisig, multisigAbi, provider);

    await set(nextState);
  },
  

  subscribeEthEvents(ethereum) {
    const { connect } = get();
    ethereum.on('connect', () => {
      //console.log('ETH: connect');
      connect();
    });
    ethereum.on('disconnect', () => {
      //console.log('ETH: disconnect');
      connect();
    });
    ethereum.on('accountsChanged', () => {
      //console.log('ETH: accountsChanged');
      connect();
    });
    ethereum.on('chainChanged', () => {
      //console.log('ETH: chainChanged');
      connect();
    });
  },

  setOfflineChain(chainId) {
    set({ offlineChain: chainId });
  },
  
}));

export default useStore;
