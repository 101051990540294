import React, { useState, useEffect } from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import Web3 from 'web3';
//import { toast } from 'react-toastify';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Button
} from '@mui/material';

import useStore from 'src/_store';
import { getMethodName, getAttributes } from 'src/_data/decodeTransaction';


const ViewTransaction = ({
  transactionVeiw,
  setTransactionVeiw,
  setReloadData
}) => {
  const web3 = new Web3();

  const [multisig, provider] = useStore((state) => [state.multisig, state.provider]);
  //const account = useStore((state) => state.address);

  const [methodAttributes, setMethodAttributes] = useState([]);

  const [processing, setProcessing] = useState(false);

  
  useEffect(() => {
    if (transactionVeiw !== null && provider !== null) {
      let attrs = getAttributes(transactionVeiw.data);
      setMethodAttributes(attrs);
    }
  }, [transactionVeiw, provider]);

  const reloadTransactioin = async () => {
    let tx = await multisig.getTransaction(transactionVeiw.id);

    setTransactionVeiw(null);
    setTransactionVeiw(tx);
    setReloadData(Date.now());
  };

  const confirmTransaction = async (id) => {
    const signer = provider.getSigner();
    setProcessing(true);

    try {
      let tx = await multisig.connect(signer).confirmTransaction(id);
      await tx.wait();
    } catch (error) {
      console.log(error);
    }
    
    setTimeout(() => {
      setProcessing(false);
      reloadTransactioin();
    }, 4000);
  }
  
  const executeTransaction = async (id) => {
    const signer = provider.getSigner();
    setProcessing(true);
    
    try {
      let tx = await multisig.connect(signer).executeTransaction(id);
      await tx.wait();
    } catch (error) {
      console.log(error);
    }
    
    setTimeout(() => {
      setProcessing(false);
      reloadTransactioin();
    }, 4000);
  }

  const declineTransaction = async (id) => {
    const signer = provider.getSigner();
    setProcessing(true);
    
    try {
      let tx = await multisig.connect(signer).declineTransaction(id);
      await tx.wait();
    } catch (error) {
      console.log(error);
    }
    
    setTimeout(() => {
      setProcessing(false);
      reloadTransactioin();
    }, 4000);
  }

  return (
    <>
      {transactionVeiw !== null && <>
      <Box my={2}>
        <Typography
          color="textPrimary"
          variant="body1"
        >
          Multisig Transaction #{parseInt(transactionVeiw.id)}
        </Typography>
      </Box>
      <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                {transactionVeiw.smartContract}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Date
              </TableCell>
              <TableCell>
                {moment(transactionVeiw.timestamp * 1000).format('YYYY-MM-DD HH:mm')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Method
              </TableCell>
              <TableCell>
                {getMethodName(transactionVeiw.data)}
              </TableCell>
            </TableRow>
            {methodAttributes.map((ma, index) => (
              <TableRow key={index}>
                <TableCell>
                  {ma.name}
                </TableCell>

                <TableCell>
                  {ma.isAmount === true &&
                    <NumberFormat value={web3.utils.fromWei((ma.value).toString())} displayType={'text'} thousandSeparator={true} />
                  }

                  {ma.isAmount !== true &&<>
                    {ma.value}
                  </>}
                </TableCell>
               </TableRow>
                ))}
            <TableRow>
              <TableCell colSpan={2}>
                Confimed By
              </TableCell>
            </TableRow>
              {transactionVeiw.approvedBy.map((approvedBy, index) => (
              <TableRow key={index}>
                <TableCell colSpan={2}>
                  {approvedBy}
              </TableCell>
            </TableRow>))}
          </TableBody>
        </Table>

        {transactionVeiw.executed !== true && transactionVeiw.declined !== true  && <Grid
          container
          spacing={3}
        >
          <Grid item lg={6} md={12} sm={12}>
          {transactionVeiw.approvedBy.length < 2 && <Button
                color="success"
                fullWidth
                size="small"
                variant="contained"
                disabled={processing}
                onClick={() => {
                  confirmTransaction(transactionVeiw.id);
                }}
              >
              Confirm
            </Button>}
            {transactionVeiw.approvedBy.length >= 2 && <Button
                color="success"
                fullWidth
                size="small"
                variant="contained"
                disabled={processing}
                onClick={() => {
                  executeTransaction(transactionVeiw.id);
                }}
              >
              Execute
            </Button>}
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Button
                color="error"
                fullWidth
                size="small"
                variant="contained"
                disabled={processing}
                onClick={() => {
                  declineTransaction(transactionVeiw.id);
                }}
              >
              Decline
            </Button>
          </Grid>
        </Grid>}
      </>}
    </>
  );
};


export default ViewTransaction;
