import React from 'react';
import Button from '@mui/material/Button';

import useStore from 'src/_store';


const Connect = () => {
  const initializeStore = useStore((state) => state.initialize);
  const logoutStore = useStore((state) => state.logout);
  const address = useStore((state) => state.address);

  const addressToLabel = (address) => {
    if (address) {
      const addressLength = address.length;

      let res = address.substring(0, 5) + '...' + address.substring(addressLength-4, addressLength);
      return res;
    }
  };

  return (
    <>
      {(address === null || address === undefined) && <Button size="small" color="primary" variant="contained"
        onClick={() => {
          initializeStore();
        }}
      >Connect Wallet</Button>}
      {address !== null && address !== undefined && <Button size="small" color="primary" variant="contained"
        onClick={() => {
          logoutStore();
        }}
      >{addressToLabel(address)}</Button>}
    </>
  );
};

export default Connect;
