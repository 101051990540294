import React, { useState, useEffect, useCallback } from 'react';

import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Chip
} from '@mui/material';

import useStore from 'src/_store';

import { getMethodName } from 'src/_data/decodeTransaction';


const ListTransactions = ({
  account,
  setTransactionVeiw,
  reloadData
}) => {
  const multisig = useStore((state) => state.multisig);

  useEffect(() => {
    if (multisig) {
      loadTransactions();
    }

    return () => {
      setListPending(null);
    };
  }, [multisig, reloadData]);

  const [listPending, setListPending] = useState(null);

  const loadTransactions = useCallback(async () => {
    if (multisig) {
      let total = await multisig.transactionsTotal();

      let start = total > 30 ? (total - 30) : 0;
      let max = total > 30 ? 30 : total;

      
      let transactions = await multisig.getTransactions(start, max);

      let transactionsReversed = [];

      for (let i in transactions) {
        transactionsReversed.unshift(transactions[i]);
      }

      setListPending(null);
      setListPending(transactionsReversed);
    }
  }, [multisig]);

  return (
    <>
      <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                Time
              </TableCell>
              <TableCell>
                Transaction
              </TableCell>
              <TableCell>
                Status
              </TableCell>
              <TableCell>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listPending !== null && listPending.map((tx, index) => (
              <TableRow
                hover
                key={index}
              >
                <TableCell>
                  {moment(tx.timestamp * 1000).format('YYYY-MM-DD HH:mm')}
                </TableCell>
                <TableCell>
                  {getMethodName(tx.data)}
                </TableCell>
                <TableCell>
                  {tx.executed === true && <Chip label="Executed" color="success" />}
                  {tx.executed !== true && tx.declined !== true && <Chip label="Pending" color="success" variant="outlined" />}
                  {tx.declined === true && <Chip label="Declined" color="error" />}
                </TableCell>
                <TableCell>
                <Button
                  color="primary"
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setTransactionVeiw(tx);
                  }}
                >
                  View
                </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> 
    </>
  );
};


export default ListTransactions;
