import React, { useEffect, useState, useCallback } from 'react';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import * as QueryString from "query-string";

import useStore from 'src/_store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { getNetworks } from 'src/_data/networks';

import Header from './layouts/Header'
import IndexView from './views/index/IndexView';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffc107',
    },
    secondary: {
      main: '#212529',
    },
  },
});

function App() {
  const initializeStore = useStore((state) => state.initialize);
  const provider = useStore((state) => state.provider);
  const address = useStore((state) => state.address);
  const networkChanged = useStore((state) => state.networkChanged);

  const [storeLoaded, setStoreLoaded] = useState(false);

  const parsedQuery = QueryString.parse(window.location.search);

  useEffect(() => {
    loadStore();
  }, [parsedQuery.network, networkChanged]);

  const loadStore = useCallback(async () => {
    if (!parsedQuery.network) {
      let networks = getNetworks();
      window.location.href = '?network=' + networks[0].id;
      return false;
    }
    await initStore();
    
    setTimeout(() => {
      setStoreLoaded(true);
    }, 1000);
  }, [address, provider, networkChanged]);

  const initStore = useCallback(async () => {
    if (parsedQuery.network) {
      await initializeStore(parsedQuery.network);

      if (networkChanged === true) {
        window.location.reload(true);
        return false;
      }
    }
  }, [parsedQuery.network, networkChanged]);
  

  return (
    <ThemeProvider theme={theme}>
      {storeLoaded === true && <div className="App">
        <Header />

        {provider !== null && <IndexView  />}
      </div>}
      <ToastContainer
        position={'top-right'}
        autoClose={8000}
        hideProgressBar={true}
        newestOnTop={true}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
    </ThemeProvider>
  );
}

export default App;
