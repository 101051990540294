import React, { useState, useEffect, useCallback } from 'react';
import * as QueryString from "query-string";
import NumberFormat from 'react-number-format';
import Web3 from 'web3';

import {
  Container,
  Grid,
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Typography
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';

import useStore from 'src/_store';

import { getNetworks, getNetwork } from 'src/_data/networks';

import ListTransactions from './ListTransactions';
import ViewTransaction from './ViewTransaction';

const IndexView = () => {
  const web3 = new Web3();

  const parsedQuery = QueryString.parse(window.location.search);

  const multisig = useStore((state) => state.multisig);
  const token = useStore((state) => state.token);

  const provider = useStore((state) => state.provider);
  const account = useStore((state) => state.address);
  const networkChanged = useStore((state) => state.networkChanged);
  

  const [allNetworks, setAllNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState('');
  const [networkSettings, setNetworkSettings] = useState(null);

  const [hasAccess, setHasAccess] = useState(null);
  const [transactionVeiw, setTransactionVeiw] = useState(null);

  const [tokenBalance, setTokenBalance] = useState(0);
  const [tokenAllowance, setTokenAllowance] = useState(0);

  const [reloadData, setReloadData] = useState(0);
  

  useEffect(() => {
    loadNetwork();

    if (account !== null && account !== undefined && multisig !== null) {
      loadData();
    }

  }, [account, multisig, provider, networkChanged]);

  const loadNetwork = async () => {
    if (parsedQuery.network) {
      setSelectedNetwork(parsedQuery.network);
      let network = getNetwork(parsedQuery.network);
      setNetworkSettings(network);

      //if (provider === null && networkChanged === true) {
      //  window.location.reload(true);
      //}

      let networks = getNetworks();
      setAllNetworks(networks);
    }
  };

  const loadData = useCallback(async () => {
    if (multisig && provider) {
      if (account !== null && account !== undefined && multisig !== null) {
        let isSigner = await multisig.signers(account);
        setHasAccess(isSigner);
        setReloadData(Date.now());

        let balance = await token.balanceOf(multisig.address);
        setTokenBalance(balance);
      }
    }
  }, [multisig, account]);


  const handleChange = (event) => {
    window.location.href = '?network=' + event.target.value;
  };

  return (
    <>
      <Container maxWidth={false}>
        <Grid
          container
          spacing={3}
          my={2}
        >
          <Grid item lg={6} md={7} sm={12}>
          {multisig === null && <Box>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Please connect a Wallet
                </Typography>
            </Box>}

            {hasAccess === false && <Box my={2}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  Connected Wallet is not a Signer
                </Typography>
            </Box>}
            {multisig!== null && hasAccess === true && networkSettings !== null && <Box my={2}>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                Network: {networkSettings.name}
              </Typography>
              <Typography
                color="textPrimary"
                variant="body2"
              >
                Multisig: {networkSettings.multisig}
              </Typography>

              <Typography
                color="textPrimary"
                variant="body2"
              >
                Multisig Balance: <NumberFormat value={web3.utils.fromWei((tokenBalance).toString())} displayType={'text'} thousandSeparator={true} /> BSGG
              </Typography>
            </Box>}
          </Grid>
          <Grid item lg={6} md={5} sm={12} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box sx={{ width: 200 }}>
              <FormControl fullWidth>
                <InputLabel id="selectedNetworkLabel">Network</InputLabel>
                <Select
                  labelId="selectedNetworkLabel"
                  id="selectedNetwork"
                  value={selectedNetwork}
                  label="Network"
                  onChange={handleChange}
                >
                  {allNetworks.map((network) => (
                    <MenuItem key={network.id} value={network.id}>{network.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>


        {multisig!== null && hasAccess === true &&
        <Grid
          container
          spacing={3}
        >
          <Grid item lg={6} md={7} sm={12}>
            {account !== null && account !== undefined && <ListTransactions account={account} provider={provider} setTransactionVeiw={setTransactionVeiw} reloadData={reloadData} />}
          </Grid>
          <Grid item lg={6} md={5} sm={12}>
            <ViewTransaction transactionVeiw={transactionVeiw} setTransactionVeiw={setTransactionVeiw} setReloadData={setReloadData} />
          </Grid>
        </Grid>}

      </Container>
    </>
  );
};

export default IndexView;
